<template>
    <div id="problem">
        <Header></Header>
        <Culture>
            <h3 slot="title1">常见问题</h3>
            <p slot="title3">COMMON PROBLEM</p>
        </Culture>
        <div class="problem_t"></div>
        <div class="problem_bg w100">
            <img src="../assets/imgs/problem_bg.png" alt="" />
        </div>
        <!-- 1 -->
        <div class="pro1 w1200">
            <div class="pro1_con pro_con">
                <div class="pro1_con_l pro_con_l">
                    <div class="pro_con_l_b">
                        <p>1</p>
                    </div>
                </div>
                <div class="pro1_con_r pro_con_r">
                    <h3>为什么用户注册不了工惠通？</h3>
                    <p>工惠通平台是为企业客户服务，不支持个人客户注册；需要企业提供白名单，后台统一登记后， 才能够登录。</p>
                </div>
            </div>
        </div>
        <!-- 2 -->
        <div class="pro2 w1200">
            <div class="pro2_con pro_con">
                <div class="pro2_con_l pro_con_l">
                    <div class="pro_con_l_b">
                        <p>2</p>
                    </div>
                </div>
                <div class="pro2_con_r pro_con_r">
                    <h3>工会慰问品没收到或者有问题，怎么办？</h3>
                    <p>点击“我的”→“我的订单”→ 查看订单详情 → “查看物流”即可查询物流的具体送货状态。 才能够登录。</p>
                    <div class="con_r_imgs">
                        <div class="imgs2">
                            <img src="../assets/imgs/1.jpg" alt="">
                            <p>我的</p>
                        </div>
                        <div class="imgs2">
                            <img src="../assets/imgs/2.png" alt="">
                            <p>我的订单——>查看物流</p>
                        </div>
                        <div class="imgs2">
                            <img src="../assets/imgs/3.jpg" alt="">
                            <p>查看物流状态</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 3 -->
        <div class="pro3 w1200">
            <div class="pro3_con pro_con">
                <div class="pro3_con_l pro_con_l">
                    <div class="pro_con_l_b">
                        <p>3</p>
                    </div>
                </div>
                <div class="pro3_con_r pro_con_r">
                    <h3>变更手机号?</h3>
                    <p>工惠通用户需要变更手机号时，需要提报信息给对应的工会工作人员确认后，由系统管理员进行手机号变更，系统将会短信通知员工，使用新手机号重设登录密码。</p>
                </div>
            </div>
        </div>
        <!-- 4 -->
        <div class="pro2 w1200">
            <div class="pro2_con pro_con">
                <div class="pro2_con_l pro_con_l">
                    <div class="pro_con_l_b">
                        <p>4</p>
                    </div>
                </div>
                <div class="pro2_con_r pro_con_r">
                    <h3>工惠优选购买的商品有问题怎么办？</h3>
                    <p>在工惠优选中，点开“我的”，进入“售后服务”，选择有问题的商品，进行“申请售后”操作。</p>
                    <div class="con_r_imgs">
                        <div class="imgs4">
                            <img src="../assets/imgs/4.jpg" alt="">
                            <p>“我的”，进入“售后服务”</p>
                        </div>
                        <div class="imgs4">
                            <img src="../assets/imgs/5.jpg" alt="">
                            <p>选择有问题的商品-->进行“申请售后”</p>
                        </div>
                        <!-- <div class="imgs1">
                            <img src="../assets/imgs/3.png" alt="">
                            <p>查看物流状态</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Culture from '../components/Culture'
import Header from '../components/Header'
import Footer from '../components/Footer/FootSec.vue'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Culture
  }
}
</script>

<style lang="less" scoped>
.problem_bg{
    img{
        width: 100%;
    }
}
.pro1,.pro3{
    margin: 95px auto 70px auto;
    background-color: #1A2E87;
}
.pro1_con,.pro3_con{
    height: 250px;
}
.pro_con{
    box-shadow: 0px 4px 8px 0px rgba(44, 91, 150, 0.24);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pro_con_l{
        width: 20%;
        display: inline-block;
        text-align: center;
        .pro_con_l_b{
            position: relative;
            height: 80px;
            width: 80px;
            border: 2px solid #fff;
            border-radius: 50%;
            margin: 0 auto;
            p{
                font-size: 36px;
                color: #fff;
                position: absolute;
                top: 20px;
                left: 30px;
                font-weight: bold;
            }
        }
    }
    .pro_con_r{
        width: 80%;
        h3{
            font-weight: bold;
            font-size: 30px;
            font-style: italic;
            color: #fff;
            line-height: 62px;
        }
        p{
            font-size: 18px;
            font-style: italic;
            color: #fff;
            line-height: 42px;
            margin-bottom: 20px;
        }
        .con_r_imgs{
            width: 80%;
            display: flex;
            justify-content: space-around;
            justify-items: center;
            text-align: center;
            .imgs2{
                width: 30%;
                img{
                    width: 100%;
                }
            }
            .imgs4{
                width: 38%;
                img{
                    width: 100%;
                }
            }
        }
    }
}
.pro2{
    border: 1px solid #E6E6E6;
    margin-bottom: 30px;
    .pro2_con{
        .pro2_con_l{
            .pro_con_l_b{
                border-color: #1A2E87;
                margin-bottom: 300px;
                p{
                    color: #1A2E87;
                }
            }
        }
        .pro2_con_r{
            h3{
                margin-top: 20px;
                font-size: 30px;
                font-weight: bold;
                font-style: italic;
                color: #000;
                line-height: 62px;
            }
            p{
                font-weight: 400;
                color: #999999;
                line-height: 62px;
            }
        }
    }
}
</style>
